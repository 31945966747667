import {Suspense} from 'react';
import {renderRoutes} from "react-router-config";
import Spin from "antd/lib/spin";
import {LoadingOutlined} from "@ant-design/icons";

function AuthLayout(props){
    const {route} = props;

    return (
        <>
            <Suspense fallback={<Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}>
                {renderRoutes(route.routes)}
            </Suspense>
        </>
    );
}

export default AuthLayout;
