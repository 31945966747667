import { useState } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

// const { SubMenu } = Menu;

function SidebarMenu(){
    // const location = useLocation();
    const [current, setCurrent] = useState('mail')

    const handleClick = e => {
        console.log('click ', e);
        setCurrent(e.key);
      };

    const isAdmin=()=>{
        return false
    }

    return (
        // <Menu theme="dark" mode="horizontal" onClick={handleClick} mode="inline" defaultSelectedKeys={[index.toString()]}>
        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" style={{paddingLeft:"10%"}}>
            <Menu.Item key="logo" >
                <Link to="/home">
                    <img src="/imgs/logoWithoutInfo.jpg" alt='Logo' style={{height:"50px"}}/>
                </Link>
            </Menu.Item>
            {/* <Menu.Item key="about" >
                <Link to="/about">ABOUT</Link>
            </Menu.Item> */}
            <Menu.Item key="xwros" style={{marginLeft:"21%"}}>
                <Link to="/ourspace">Ο ΧΩΡΟΣ ΜΑΣ</Link>
            </Menu.Item>
            <Menu.Item key="upiresies">
                <Link to="/services">ΥΠΗΡΕΣΙΕΣ</Link>
            </Menu.Item>
            {/* <SubMenu key="upiresies" title="ΥΠΗΡΕΣΙΕΣ" href='/products'>
                <Menu.ItemGroup title="ΑΙΣΘΗΤΙΚΗ ΠΡΟΣΩΠΟΥ">
                    <Menu.Item key="setting:1"><Link to='/services'>Θεραπείες προσώπου</Link></Menu.Item>
                    <Menu.Item key="setting:2">Θεραπείες ματιών και χειλιών</Menu.Item>
                    <Menu.Item key="setting:3">Αντιγήρανση</Menu.Item>
                    <Menu.Item key="setting:4">Για εκείνον</Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title="Αποτρίχωση">
                    <Menu.Item key="setting:5">Αποτρίχωση Προσώπου</Menu.Item>
                    <Menu.Item key="setting:6">Αποτρίχωση Σώματος</Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title="ΑΙΣΘΗΤΙΚΗ ΣΩΜΑΤΟΣ">
                    <Menu.Item key="setting:7">Αδυνάτισμα</Menu.Item>
                    <Menu.Item key="setting:8">Θεραπείες Ευεξίας</Menu.Item>
                    <Menu.Item key="setting:9">Για Εκείνον</Menu.Item>
                </Menu.ItemGroup>
            </SubMenu> */}
            <Menu.Item key="product">
                <Link to='/products'>ΠΡΟΙΟΝΤΑ</Link>
            </Menu.Item>
            {/* <Menu.Item key="blog">
                BLOG
            </Menu.Item> */}
            <Menu.Item key="contact">
                <Link to="/contact">ΕΠΙΚΟΙΝΩΝΙΑ</Link>
            </Menu.Item>
            {
                isAdmin()?
                <Menu.Item key="admin">
                    ADMIN
                </Menu.Item>
                : null
            }
            {/* <Menu.Item key="signup" style={{float:"right"}}>
                ΕΓΓΡΑΦΗ
            </Menu.Item>
            <Menu.Item key="login" style={{float:"right"}}>
                LOGIN
            </Menu.Item> */}
            {/* {
                MenuItems.map((menuItem, index) => (
                    <Menu.Item key={index} icon={menuItem.icon}>
                        <Link to={menuItem.href}>
                            {menuItem.title}
                        </Link>
                    </Menu.Item>
                ))
            } */}
        </Menu>
    );
}

export default SidebarMenu;
